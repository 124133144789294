import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import React, { FC, useRef, useState, useMemo, useEffect } from 'react';
import Compress from 'compress.js';

import 'quill-mention';
import Emoji from '@social/quill-emoji';
import MagicUrl from 'quill-magic-url';
import ReactQuill, { Quill } from 'react-quill';

import cn from 'classnames';

import './quill-modules/icons';
import toArray from 'lodash/toArray';
import GifFieldDialog from './GifFiledDialog';
import MathFieldDialog from './MathFieldDialog';
import ReplacedVideoUrl from './quill-modules/replaced-video-url';
import ReplacedVideo from './quill-modules/replaced-video';
import ReplacedImage from './quill-modules/replaced-image';
import ReplacedLink from './quill-modules/replaced-link';
import CustomImageUploader from './quill-modules/custom-image-uploader';
import CustomFormula from './quill-modules/custom-formula';
import CustomSnowTheme from './quill-modules/custom-theme/custom-theme';

import { uploadImage } from '../../services/SocialAPI';
import { getRandomId } from '../../utils/linkHelpers';
import { AttachmentButton } from '../Attachments';
import FormFieldLabel from '../FormFieldLabel';
import FormFieldHint from '../FormFieldHint/FormFieldHint';
import { ROOT_MODAL_ID } from '../DialogModal';

// eslint-disable-next-line import/no-webpack-loader-syntax, import/extensions
import emojiIcon from '!!raw-loader!../../assets/images/editor/emoji.svg';
import { ReactComponent as AttachmentIcon } from '../../assets/images/editor/attachment.svg';
import { ReactComponent as GifIcon } from '../../assets/images/editor/gif.svg';

import 'katex/dist/katex.css';
import 'quill-mention/dist/quill.mention.css';
import 'react-quill/dist/quill.snow.css';
import '../../scss/quill-emoji.scss';
import '../../scss/quill-fixes.scss';
import styles from './TextEditor.module.scss';

import { Attachment } from '../../types';
import { sendEventSelectRichTextOption } from '../../utils/events';
import { TextEditorProps } from './TextEditor.props';

import { useCommonDataContext } from '../../providers/commonDataContext';
import { useA11yLiveContext } from '../../providers/a11yLiveContext';

const compress = new Compress();

Quill.register(
  {
    'modules/emoji': Emoji,
    'modules/imageUploader': CustomImageUploader,
    'modules/magicUrl': MagicUrl,
    'modules/magicVideoUrl': ReplacedVideoUrl,
    'formats/formula': CustomFormula,
    'formats/video': ReplacedVideo,
    'formats/image': ReplacedImage,
    'formats/link': ReplacedLink,
    'themes/snow': CustomSnowTheme,
  },
  true,
);

async function suggestPeople(searchTerm: string) {
  const allPeople = [
    {
      id: 'fakeuser',
      value: 'Fake User',
    },
    {
      id: 'fakeuser2',
      value: 'Fake User2',
    },
  ];
  return allPeople.filter((person) => person.value.includes(searchTerm));
}

const getHtmlCharCount = (html?: string) =>
  toArray(html?.replace(/<[^>]*>?/gm, '').replace(/\uFEFF/g, '')).length || 0;

const TextEditor: FC<TextEditorProps> = ({
  postContent = { html: '' },
  setPostContent,
  placeholder,
  errors,
  submitActionText,
  onCancel,
  onSubmit,
  mentionedUser,
  pickFile,
  uploadingFiles,
  attachmentError,
  inlineMode,
  showPanel = false,
  onImageLoading,
  disableAutoFocus,
  htmlId,
}) => {
  const [showMathDialog, setShowMathDialog] = useState(false);
  const [showGif, setShowGif] = useState(false);
  const [cursorPosition, setCursorPosition] = useState(0);
  const shortId = useRef(getRandomId());
  const [reactQuillRef, setReactQuillRef] = useState<ReactQuill | null>();
  const [smallMobile, setSmallMobile] = useState<boolean>(false);

  const { env, token, userId, onLoginRequire } = useCommonDataContext();
  const { polite: livePolite } = useA11yLiveContext();

  const quillModules = useMemo(
    () => ({
      keyboard: {
        bindings: {
          tab: {
            key: 9,
            handler() {
              return true;
            },
          },
        },
      },
      toolbar: {
        container: `#${shortId.current}`,
      },
      'emoji-toolbar': {
        buttonIcon: emojiIcon,
        renderRootId: !inlineMode && ROOT_MODAL_ID,
      },
      'emoji-shortname': true,
      mention: {
        mentionDenotationChars: [], // not enabled
        dataAttributes: ['id'],
        async source(
          searchTerm: string,
          renderList: (param: { id: string; value: string }[]) => void,
        ) {
          const matchedPeople = await suggestPeople(searchTerm);
          renderList(matchedPeople);
        },
      },
      magicUrl: true,
      magicVideoUrl: true,
      imageUploader: {
        upload: (file: File) =>
          new Promise((resolve) => {
            onImageLoading?.(true);
            compress
              .compress([file], {
                size: 1, // the max size in MB, defaults to 2MB
                quality: 0.8, // the quality of the image, max is 1,
                maxWidth: 1280, // the max width of the output image, defaults to 1920px
                maxHeight: 1280, // the max height of the output image, defaults to 1920px
                resize: true, // defaults to true, set false if you do not want to resize the image width and height
              })
              .then((results) => {
                const img1 = results[0];
                const base64str = img1.data;
                const imgExt = img1.ext;
                const compressedFile = Compress.convertBase64ToFile(
                  base64str,
                  imgExt,
                );
                uploadImage(token, compressedFile, env, file.name).then(
                  (data) => {
                    resolve(data.file);
                    onImageLoading?.(false);
                  },
                );
              });
          }),
      },
    }),
    [],
  );

  const quillFormats = [
    'bold',
    'italic',
    'underline',
    'blockquote',
    'mention',
    'link',
    'emoji',
    'image',
    'imageBlot',
    'video',
    'formula',
  ];

  const [showRichIcons, setShowRichIcons] = useState(showPanel);

  useEffect(() => {
    if (
      mentionedUser &&
      reactQuillRef &&
      typeof reactQuillRef.getEditor === 'function'
    ) {
      const quillEditor = reactQuillRef.getEditor();
      const mention = quillEditor.getModule('mention');
      quillEditor.setText('');
      mention.insertItem(
        {
          id: mentionedUser.userId,
          value: `${mentionedUser.userName}`,
          denotationChar: '@',
        },
        true,
      );
    }
  }, [mentionedUser, reactQuillRef]);

  useEffect(() => {
    if (
      reactQuillRef &&
      reactQuillRef.getEditor &&
      reactQuillRef.getEditor().root
    ) {
      const { root: editArrea } = reactQuillRef.getEditor();

      editArrea.setAttribute('role', 'textbox');
      editArrea.setAttribute('aria-label', 'Post');

      if (htmlId) {
        editArrea.setAttribute('id', htmlId);
      }
    }
  }, [reactQuillRef]);

  const attachmentIconHandler = () => {
    pickFile();
    sendEventSelectRichTextOption('Attachment');
  };

  const handleAttachmentDelete = (attachment: Attachment) => {
    const attachments = (postContent.attachments || []).filter(
      ({ url }) => url !== attachment.url,
    );
    setPostContent({ ...postContent, attachments });
  };

  const handleContentChange = (html: string) =>
    setPostContent((prevContent) =>
      getHtmlCharCount(html) > 5000 ? prevContent : { ...postContent, html },
    );

  const handleKeyDown = (e: any) => {
    e.stopPropagation();
  };

  const refHandler = (ref: any) => {
    if (ref && !reactQuillRef && !disableAutoFocus) {
      ref.focus();
    }
    if (ref) {
      return setReactQuillRef(ref);
    }
    return ref;
  };

  const fieldLengthHint = (len: number, max: number): string => {
    if (len === max) {
      livePolite.read('You have reached the maximum field length.');
    }

    return `${len}/${max}`;
  };

  const closeMathField = () => {
    setShowMathDialog(false);
    const mathFieldElement = document.querySelector('#math-field');
    if (mathFieldElement) {
      // @ts-ignore
      mathFieldElement.executeCommand(['hideVirtualKeyboard']);
    }
  };

  const getCursorPosition = () => {
    const quillElement = reactQuillRef?.getEditor();
    const range = quillElement?.getSelection(true);
    if (range) {
      return range.index + range.length;
    }
    return 0;
  };

  const showFormulaDialog = () => {
    if (showMathDialog) {
      setShowMathDialog(false);
    } else {
      const position = getCursorPosition();
      setCursorPosition(position);
      sendEventSelectRichTextOption('Formula');
      setShowMathDialog(true);
    }
  };

  const closeGifField = () => {
    setShowGif(false);
  };

  const showGifDialog = () => {
    const position = getCursorPosition();
    setCursorPosition(position);
    sendEventSelectRichTextOption('Gif');
    setShowGif(true);
  };

  const checkEditorSize = () => {
    const editor: any = reactQuillRef?.getEditor();
    setSmallMobile((editor?.container?.clientWidth || 0) < 330);
  };

  useEffect(() => {
    if (!reactQuillRef?.getEditor()) return () => {};
    checkEditorSize();
    window.removeEventListener('resize', checkEditorSize);
    window.addEventListener('resize', checkEditorSize);
    return () => {
      window.removeEventListener('resize', checkEditorSize);
    };
  }, [reactQuillRef]);

  return (
    <>
      <GifFieldDialog
        showGif={showGif}
        closeGifField={closeGifField}
        cursorPosition={cursorPosition}
        reactQuillRef={reactQuillRef}
      />

      <MathFieldDialog
        showMathDialog={showMathDialog}
        closeMathField={closeMathField}
        cursorPosition={cursorPosition}
        reactQuillRef={reactQuillRef}
      />

      {!inlineMode || showRichIcons ? (
        <div className={cn(styles.questionEditor, 'question-wrapper')}>
          {inlineMode && (
            <ReactQuill
              ref={refHandler}
              theme="snow"
              modules={quillModules}
              formats={quillFormats}
              placeholder={placeholder}
              value={postContent.html}
              onChange={handleContentChange}
              onKeyDown={handleKeyDown}
              className={cn({
                [styles.inlineQuill]: inlineMode,
                [styles.noText]:
                  (postContent.html === '' ||
                    postContent.html === '<p><br></p>') &&
                  smallMobile,
              })}
            />
          )}

          <div
            role="toolbar"
            aria-label="Text Formatting and Insert"
            id={shortId.current}
            className={inlineMode ? styles.inlineToolbar : ''}
          >
            <ul className="ql-formats">
              <li>
                <IconButton
                  className="ql-bold"
                  aria-label="Bold"
                  onClick={() => sendEventSelectRichTextOption('Bold')}
                />
              </li>
              <li>
                <IconButton
                  className="ql-italic"
                  aria-label="Italic"
                  onClick={() => sendEventSelectRichTextOption('Italic')}
                />
              </li>
              <li>
                <IconButton
                  className="ql-underline"
                  aria-label="Underline"
                  onClick={() => sendEventSelectRichTextOption('Underline')}
                />
              </li>
              <li>
                <IconButton
                  className="gif"
                  aria-label="Gif"
                  onClick={showGifDialog}
                >
                  <GifIcon />
                </IconButton>
              </li>
              <li>
                <IconButton
                  className="ql-emoji"
                  aria-label="Emoji"
                  onClick={() => sendEventSelectRichTextOption('Emoji')}
                />
              </li>
              <li>
                <IconButton
                  className="ql-link"
                  aria-label="Hyperlink"
                  onClick={() => sendEventSelectRichTextOption('Hyperlink')}
                />
              </li>
              <li>
                <IconButton
                  className="ql-image"
                  aria-label="Attach image"
                  onClick={() => sendEventSelectRichTextOption('Image')}
                />
              </li>
              <li>
                <IconButton
                  className="ql-video"
                  aria-label="Insert video"
                  onClick={() => sendEventSelectRichTextOption('Video')}
                />
              </li>
              <li>
                <IconButton
                  className="attachmentobject"
                  aria-label="Attachments"
                  onClick={attachmentIconHandler}
                >
                  <AttachmentIcon />
                </IconButton>
              </li>
              <li>
                <IconButton
                  className="ql-formula"
                  aria-label="Insert formula"
                  onClick={showFormulaDialog}
                />
              </li>
            </ul>
          </div>

          {inlineMode && (
            <div className={cn(styles.toolbarActionBtns)}>
              <FormFieldHint hasError={!!errors?.length} hint={errors?.[0]} />
              <div>
                {onCancel && (
                  <Button className={styles.cancelButton} onClick={onCancel}>
                    Cancel
                  </Button>
                )}
                {onSubmit && (
                  <Button
                    className={styles.saveButton}
                    onClick={() => onSubmit(postContent)}
                  >
                    {submitActionText}
                  </Button>
                )}
              </div>
            </div>
          )}

          {!inlineMode && (
            <ReactQuill
              ref={refHandler}
              theme="snow"
              modules={quillModules}
              formats={quillFormats}
              placeholder={placeholder}
              value={postContent.html}
              onChange={handleContentChange}
              onKeyDown={handleKeyDown}
              className={inlineMode ? styles.inlineQuill : ''}
            />
          )}
        </div>
      ) : (
        <button
          type="button"
          aria-label={placeholder}
          className={styles.inputPlaceholder}
          onClick={() => {
            if (!userId && onLoginRequire) {
              onLoginRequire();
              return;
            }

            setShowRichIcons(true);
          }}
        >
          {placeholder}
        </button>
      )}

      {!inlineMode && (
        <FormFieldHint
          hasError={!!errors?.length}
          hint={errors?.[0]}
          secondaryHint={fieldLengthHint(
            getHtmlCharCount(postContent?.html),
            5000,
          )}
        />
      )}

      {((postContent.attachments && !!postContent.attachments.length) ||
        !!uploadingFiles.length) && (
        <div className="item attachmentBlock">
          <FormFieldLabel
            label={`Attachments${
              postContent.attachments?.length
                ? ` (${postContent.attachments?.length})`
                : ''
            }`}
          />

          <div className="attachmentList">
            {uploadingFiles.map((uploadingFile) => (
              <AttachmentButton
                key={uploadingFile.id}
                name={uploadingFile.file.name}
                progress={uploadingFile.progress}
              />
            ))}
            {postContent.attachments &&
              postContent.attachments.map((attachment) => (
                <AttachmentButton
                  key={attachment.url}
                  name={attachment.name}
                  url={attachment.url}
                  onDeleteClick={() => handleAttachmentDelete(attachment)}
                />
              ))}
          </div>
        </div>
      )}

      {attachmentError && <FormFieldHint hasError hint={attachmentError} />}
    </>
  );
};

export default TextEditor;
